import styled from "styled-components";
import axios from "axios";
import { useQuery } from "react-query";
import Title from "../../components/Title/Title";
import { useApiUrl } from "../../utils/Context/ApiContext";

interface History {
  id: number;
  title: string;
  duration: string;
  description: string;
  start: Date;
  finish: Date;
  picture: string;
}

type Histories = History[];

const CareerContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

function Career() {
  const apiUrl = useApiUrl();

  const fetchHistories = async (): Promise<Histories> => {
    const response = await axios.get<Histories>(`${apiUrl}/api/history`);

    return response.data;
  };
  const { data, error, isLoading } = useQuery<Histories, Error>(
    "histories",
    fetchHistories
  );

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    data &&
    data.length > 0 && (
      <CareerContainer className="container mx-auto">
        <Title text="Carrière" />
        <ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
          {data.map((history, index) => {
            const formattedStartDate = new Date(
              history.start
            ).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "long",
            });
            const formattedEndDate = history.finish
              ? new Date(history.finish).toLocaleDateString("fr-FR", {
                  year: "numeric",
                  month: "long",
                })
              : null;

            return (
              <li key={history.id}>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  className={
                    index % 2 === 0
                      ? "timeline-start mb-10 md:text-end"
                      : "timeline-end mb-10"
                  }
                >
                  <time className="font-mono italic">
                    {formattedStartDate}
                    {formattedEndDate ? " - " + formattedEndDate : ""}
                  </time>
                  <div className="text-lg font-black">{history.title}</div>
                  {history.description}
                </div>
                <hr />
              </li>
            );
          })}
        </ul>
      </CareerContainer>
    )
  );
}

export default Career;
