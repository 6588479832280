import React, { ReactNode } from "react";
import styled, { createGlobalStyle } from "styled-components";

const StyledGlobalStyle = createGlobalStyle`
    * {
      // border:1px solid red;
    }

    body {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      background-color: #fafafa;
      inset: 0;
    }

    .container {
      background-color: #fff;
    }
`;

export const HomeContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  height: 85vh;
`;
interface GlobalStyleProps {
  children: ReactNode;
}

function GlobalStyle({ children }: GlobalStyleProps) {
  return (
    <>
      <StyledGlobalStyle />
      {children}
    </>
  );
}

export default GlobalStyle;
