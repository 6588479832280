import styled from "styled-components";
import React, { ReactNode } from "react";

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
`;

interface GlobalContainerProps {
  children: ReactNode;
}

const Main = ({ children }: GlobalContainerProps) => {
  return (
    <MainContainer className="max-h-screen p-5 bg-white flex-auto">
      {/* <div className="flex w-full max-w-7xl lg:px-8">
        <div className="w-full bg-white ring-1 ring-zinc-100 dark:bg-zinc-900 dark:ring-zinc-300/20"></div>
      </div> */}
      {children}
    </MainContainer>
  );
};

export default Main;
